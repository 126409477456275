import { InViewAnimationController } from './in-view-animation-conttoller'
import { useDidUpdateEffect, useMediaQuery } from '@conte-ltd/components-utils'
import { css } from '@emotion/react'
import { SlideUpAnimation } from './slide-up-animation'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from './container'
import { InViewFadeUp } from './in-view-fade'
import { Fragment } from 'react'
import * as React from 'react'
import { useBreakpoints, useVariables } from '@conte-ltd/components-theme'
import { Theme } from '../styles/theme'

export function Company() {
  const { color, spacing, fontSize, lineHeight } = useVariables<Theme>()
  const bp = useBreakpoints()
  const isMobile = useMediaQuery(bp.lg)
  const isDesktop = !isMobile

  return (
    <InViewAnimationController refsNum={3} threshold={0.5}>
      {({ intersectionRef, refs, controllers, inView }) => {
        useDidUpdateEffect(() => {
          const rate = inView ? 1 : -1
          controllers.forEach(({ play, updatePlaybackRate }) => {
            updatePlaybackRate(rate)
            play()
          })
        }, [isMobile])

        return (
          <div
            className={'dark-mode'}
            css={css`
              position: relative;
              overflow: hidden;

              @media ${bp.lg} {
                padding: unset;
              }
            `}
            id={'company'}
            ref={intersectionRef}
          >
            <SlideUpAnimation
              moveAmount={'100%'}
              options={{
                duration: 500,
              }}
              ref={refs[0]}
            >
              <div
                css={css`
                  position: absolute;
                  top: -100%;
                  left: 0;
                  z-index: 2;
                  width: 100%;
                  height: 100%;
                  background-color: ${color.white};
                `}
              />
            </SlideUpAnimation>

            <div
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
              `}
            >
              {isDesktop && (
                <>
                  <StaticImage
                    src={'../images/office_01.jpg'}
                    alt={'株式会社CONTE オフィス'}
                    layout={'fullWidth'}
                    objectPosition={'center 70%'}
                    objectFit={'cover'}
                    css={css`
                      width: 100vw;
                      height: 100%;
                      filter: brightness(0.5);
                    `}
                  />

                  <SlideUpAnimation
                    moveAmount={'100%'}
                    options={{
                      duration: 500,
                      delay: 500,
                    }}
                    ref={refs[1]}
                  >
                    <div
                      css={css`
                        position: absolute;
                        top: -100%;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: ${color.darkgray};
                      `}
                    />
                  </SlideUpAnimation>
                </>
              )}
            </div>

            <Container
              css={css`
                padding-top: ${spacing['2xl']};
                padding-bottom: ${spacing['2xl']};

                @media ${bp.lg} {
                  padding-top: unset;
                  padding-bottom: unset;
                }
              `}
            >
              <section
                css={css`
                  display: flex;
                  flex-direction: row;
                  align-items: stretch;
                  gap: ${spacing.md};
                  color: ${color.white};

                  @media ${bp.lg} {
                    flex-direction: column;
                    gap: 0;
                  }
                `}
              >
                <div
                  css={css`
                    position: relative;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    gap: ${spacing['2xl']};
                    width: 50rem;
                    padding: 0;

                    @media ${bp.lg} {
                      gap: ${spacing.lg};
                      width: 100%;
                      padding: ${spacing.xl} ${spacing.xs};
                    }
                  `}
                >
                  {isMobile && (
                    <div
                      css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100vw;
                        height: 100%;
                        margin: 0 calc(50% - 100vw / 2);
                      `}
                    >
                      <StaticImage
                        src={'../images/office_01sp.jpg'}
                        alt={'株式会社CONTE オフィス'}
                        objectFit={'cover'}
                        css={css`
                          position: relative;
                          width: 100%;
                          height: 100%;
                          filter: brightness(0.5);
                        `}
                      />

                      <SlideUpAnimation
                        moveAmount={'100%'}
                        options={{
                          duration: 500,
                          delay: 500,
                        }}
                        ref={refs[2]}
                      >
                        <div
                          css={css`
                            position: absolute;
                            top: -100%;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: ${color.darkgray};
                          `}
                        />
                      </SlideUpAnimation>
                    </div>
                  )}
                  <h2
                    css={css`
                      font-size: ${isMobile
                        ? fontSize['6xl']
                        : fontSize['3xl']};
                      position: relative;
                    `}
                  >
                    <InViewFadeUp>COMPANY</InViewFadeUp>
                  </h2>

                  <InViewFadeUp
                    css={css`
                      order: 1;

                      @media ${bp.md} {
                        align-self: end;
                      }
                    `}
                  >
                    <dl
                      css={css`
                        position: relative;
                        display: grid;
                        grid-template-columns: 8.2rem 1fr;
                        row-gap: ${spacing['3xs']};
                        column-gap: ${spacing.xs};
                        line-height: ${lineHeight.normal};

                        & > dt {
                          text-align: right;
                        }

                        @media ${bp.lg} {
                          grid-template-columns: 7rem 1fr;
                        }
                      `}
                    >
                      {[
                        {
                          dt: '社名',
                          dd: '株式会社CONTE / CONTE inc.',
                        },
                        {
                          dt: '所在地',
                          dd: (
                            <>
                              〒443-0034{isMobile ? <br /> : '　'}
                              愛知県蒲郡市港町13-33
                            </>
                          ),
                        },
                        {
                          dt: '事業内容',
                          dd: (
                            <>
                              ブランディング{isMobile ? <br /> : '／'}
                              グラフィックデザイン{isMobile ? <br /> : '／'}
                              WEB
                              {isMobile ? <br /> : '／'}動画
                            </>
                          ),
                        },
                        {
                          dt: '資本金',
                          dd: '400万円',
                        },
                        {
                          dt: '設立',
                          dd: '2021年5月7日',
                        },
                        {
                          dt: '代表取締役',
                          dd: '吉口 慶',
                        },
                      ].map(({ dt, dd }) => (
                        <Fragment key={dt}>
                          <dt>{dt}</dt>
                          <dd>{dd}</dd>
                        </Fragment>
                      ))}
                    </dl>
                  </InViewFadeUp>
                </div>
                <div
                  css={css`
                    flex-shrink: 0;
                    position: relative;
                    width: 63rem;
                    height: 39rem;
                    overflow: hidden;

                    @media ${bp.lg} {
                      width: 100vw;
                      margin: 0 calc(50% - 100vw / 2);
                    }

                    @media ${bp.md} {
                      height: 26rem;
                    }
                  `}
                >
                  <iframe
                    css={css`
                      position: relative;
                      top: -50%;
                      width: 100%;
                      height: 200%;
                      filter: grayscale(1);

                      @media ${bp.lg} {
                        margin: 0 calc(50% - 100vw / 2);
                      }
                    `}
                    src={
                      'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d363.3777332472275!2d137.22937026662504!3d34.82028009048895!3m2!1i1024!2i768!4f100.1!3m3!1m2!1s0x0%3A0x6835488674dd7bde!2z5qCq5byP5Lya56S-Q09OVEU!5e0!3m2!1sja!2sjp!4v1656305657825!5m2!1sja!2sjp'
                    }
                    allowFullScreen
                    loading="lazy"
                  />
                </div>
              </section>
            </Container>
          </div>
        )
      }}
    </InViewAnimationController>
  )
}
